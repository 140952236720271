<script>
import RouteLine from '../js/node/bussiness/RouteLine/RouteLine'
import BaseLayer from "../js/BaseLayer";

import {
    getRouteByTaskId
} from '@/api/project/map'


export default {
    name: 'RouteLine',
    data(){
        return {
            showRouteLine: true
        }
    },
    methods: {
        initRouteLine(map, id) {
            this.$options.routeLineLayer = new BaseLayer()
            map.layerManager.add(this.$options.routeLineLayer)
            getRouteByTaskId({
                taskId: id
            }).then(res=>{
                if (res.data.code === 200) {
                    try {
                        const data = res.data.data
                        const geojson = data.content
                        const type = geojson.geometry.type
                        const coordinates = geojson.geometry.coordinates
                        if (type === 'LineString') {
                            var positions = []
                            for (let k = 0; k < coordinates.length; k++) {
                            const coordinate = coordinates[k];
                            const position = Cesium.Cartesian3.fromDegrees(
                                ...coordinate
                            )
                            positions.push(position)
                            }
                            this.$options.node = new RouteLine({
                                id: data.id,
                                positions,
                                show: true,
                                name: data.id
                            })
                        }
                        this.$options.routeLineLayer.addNode(this.$options.node)
                    } catch (error) {
                        
                    }
                }
            })
        },

        removeRoute() {
            this.$options.routeLineLayer.clear()
        },

        updateRoute (map, id) {
            this.removeRoute()
            this.initRouteLine(map, id)
        },

        showHiddenRoute() {
            if (this.showRouteLine) {
                this.showRoute()
            } else {
                this.hiddenRoute()
            }
            // this.showRouteLine = !this.showRouteLine
        },

        showRoute() {
            if (this.$options.node) {
                this.$options.node.show = true
            }
        },

        hiddenRoute () {
            if (this.$options.node) {
                this.$options.node.show = false
            }
        }
    }
}
</script>