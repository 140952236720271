import {FlyPoint, FlyLine, FlyVisualPolygon, FlyHomePoint} from "../node/bussiness/LiveFlyRoute/FlyRoute";
import BaseLayer from "../BaseLayer";
import PlaneIcon from "@/assets/img/map/XC_64x64_.png";
import HomeImg from "@/assets/map/homePoint.png";

const userFlyRouteHook = () => {
    let map3dCollection, flylineLayer;
    let isShowVisualPolygon = false; // 是否需要绘制可视区域面
    let isShowHomePoint = false; // 是否需要绘制home起点marker
    let isFlightFollowing = false; // 是否需要飞行跟随
    let isDefaultZoomTo = true; // 是否需要默认定位
    let wsList = []; // 缓存WebSocket链接实例
    let popIds = []; // 存放divpop ID数组
    let popslist = []; // 存放浮窗集合
    return (function() {
        // 初始化地图图层实例
        const setMap = (options) => {
            const {map3d, flylineLayerID, defaultZoomTo, showVisualPolygon, showHomePoint, flightFollowing} = options;
            map3dCollection = map3d;
            isShowVisualPolygon = showVisualPolygon || false;
            isShowHomePoint = showHomePoint || false;
            isFlightFollowing = flightFollowing || false;
            isDefaultZoomTo = defaultZoomTo !== '' && defaultZoomTo !== null && defaultZoomTo !== undefined 
                ? defaultZoomTo : true;
            flylineLayer = new BaseLayer({name: flylineLayerID || "flylineLayer"}); // 初始化飞行绘制层
            map3dCollection.layerManager.add(flylineLayer); // 添加到地图中
        };
        /*
        初始化WebSocket链接
        */
        const initWebSocket = (options) => {
            const {id, entityIcon, url, callback} = options;
            const ws = new WebSocket(url);
            wsList.push(ws);
            ws.onopen = () => {
                ws.onmessage = (evt) => {
                    const wsData = JSON.parse(evt.data);
                    callback && callback({id, entityIcon, data: wsData});
                };
            }
            return ws;
        }
        /*
        处理飞行路线及可视区域坐标数据
        */
        const handlingRoutes = (options) => {
            const {id, lon, lat, homelon, homelat, yaw, pitch, roll, visualScope, flyPoint, flyLine, flyPolygon, 
                flyHomePoint, showEntityLabel, entityLabelText, entityIcon, iconSize, homeIcon, homeIconSize, lineColor, 
                lineWidth, polygonColor, polygonAlpha, isUpDate, callback} = options;
            if (!lon || !lat) return;
            const tempLon = Number(lon) * 1;
            const tempLat = Number(lat) * 1;
            const tempYaw = Number(yaw);
            const tempPitch = Number(pitch);
            const tempRoll = Number(roll);
            const position = Cesium.Cartesian3.fromDegrees(tempLon, tempLat);
            let polygonPositions = []; // 可视区面经纬度数据信息
            if (isShowVisualPolygon && visualScope) {
                const polygonPosition = visualScope.find(t => t.type === 'Polygon');
                polygonPosition && polygonPosition.coordinates[0].forEach(item => {
                    polygonPositions.push(Cesium.Cartesian3.fromDegrees(item[0], item[1]));
                });
            }
            let homePosition = "";
            if (homelon && homelat) {
                homePosition = Cesium.Cartesian3.fromDegrees(Number(homelon), Number(homelat));
            }
            // callback主要为后续操作提供执行函数，可能是初始化创建实体，可能是更新实体
            let data;
            if (callback) {
                let params;
                if (!isUpDate) {
                    params = {id, position, homePosition, yaw: tempYaw, pitch: tempPitch, roll: tempRoll, polygonPositions, 
                        icon: entityIcon, iconSize, showLabel: showEntityLabel, labelText: entityLabelText, homeIcon, 
                        homeIconSize, lineColor, lineWidth, polygonColor, polygonAlpha};
                } else {
                    params = {flyPoint, flyLine, flyPolygon, flyHomePoint, position, homePosition, 
                        yaw: tempYaw, pitch: tempPitch, roll: tempRoll, polygonPositions};
                }
                data = callback(params);
            }
            return {...data, lonArr: [tempLon, tempLat], isUpDate};
        };
        // 查找当前飞行路线是否已存在实体
        const findEntityById = (options) => {
            const {id, data} = options;
            if (data && data.hasOwnProperty("id")) delete data.id;
            if (!flylineLayer) return;
            let flyPoint = flylineLayer.getById(id);
            let flyLine = flylineLayer.getById(`${id}-line`);
            let flyPolygon, flyHomePoint;
            if (isShowVisualPolygon) {
                flyPolygon = flylineLayer.getById(`${id}-polygon`);
            }
            if (isShowHomePoint) {
                flyHomePoint = flylineLayer.getById(`${id}-HomePoint`);
            }
            if (!flyPoint) {
                // 初始化实体数据创建实体
                return handlingRoutes({...options, ...data, isUpDate: false, callback: initFlyRoute});
            } else {
                // 更新实体
                return handlingRoutes({...options, ...data, flyPoint, flyLine, flyPolygon, 
                    flyHomePoint, isUpDate: true, callback: changeFlyRoute});
            }
        };
        // 渲染飞行路线以及可视区域
        const initFlyRoute = (options) => {
            let flyPoint = new FlyPoint(options);
            flylineLayer && flylineLayer.addNode(flyPoint);
            if (isDefaultZoomTo) {
                // map3dCollection.zoomTo(flyPoint.entity);
                // map3dCollection.flyTo(flyPoint.entity, {
                //     offset: new Cesium.HeadingPitchRange(0, -90, 4000), // 设置heading,pitch和range
                //     duration: 1.5
                // });
                map3dCollection.zoomTo(
                    flyPoint.entity,
                    new Cesium.HeadingPitchRange(0, -90, 4000)
                );
            }
            let flyLine = new FlyLine({...options, id: `${options.id}-line`});
            flylineLayer && flylineLayer.addNode(flyLine);
            let flyPolygon, flyHomePoint;
            if (isShowVisualPolygon && options.polygonPositions && options.polygonPositions.length) {
                flyPolygon = new FlyVisualPolygon({...options, id: `${options.id}-polygon`});
                flylineLayer && flylineLayer.addNode(flyPolygon);
            }
            if (isShowHomePoint && options.homePosition) {
                flyHomePoint = new FlyHomePoint({...options, id: `${options.id}-HomePoint`});
                flylineLayer && flylineLayer.addNode(flyHomePoint);
            }
            // flightFollowing(flyPoint.entity);
            return {flyPoint: flyPoint.entity, flyLine: flyLine.entity, flyPolygon: flyPolygon ? flyPolygon.entity : null, 
                flyHomePoint: flyHomePoint ? flyHomePoint.entity : null, options};
        };
        // 改变飞行路线以及可视区域
        const changeFlyRoute = (options) => {
            const {flyPoint, flyLine, flyPolygon, flyHomePoint, position, yaw, pitch, roll, polygonPositions} = options;
            flyPoint.position = position;
            flyPoint.billboard.rotation = -Cesium.Math.toRadians(Number(yaw) || 0);
            // flyPoint.orientation = Cesium.Transforms.headingPitchRollQuaternion(position, 
            //     new Cesium.HeadingPitchRoll(
            //         Cesium.Math.toRadians((Number(yaw) || 0) - 90), // 航向角
            //         // Cesium.Math.toRadians(Number(pitch) || 0), // 俯仰角
            //         // Cesium.Math.toRadians(Number(roll) || 0) // 横滚角
            //         0, // 俯仰角
            //         0 // 横滚角
            //     )
            // )
            let old = flyLine.polyline.positions.getValue();
            let now = old.concat([]);
            let last = now[now.length - 1];
            if (!last || !last.equals(position)) {
                now.push(position);
            }
            flyLine.polyline.positions = new Cesium.CallbackProperty((time, result) => {
                return now;
            }, false);
            if (flyPolygon && isShowVisualPolygon && polygonPositions && polygonPositions.length) {
                flyPolygon.polygon.hierarchy = new Cesium.CallbackProperty((time, result) => {
                    return new Cesium.PolygonHierarchy(polygonPositions);
                }, false);
            }
            if (flyHomePoint && isShowHomePoint && options.homePosition) {
                flyHomePoint.position = options.homePosition;
            }
            return {flyPoint, flyLine, flyPolygon, flyHomePoint, options: {position}};
            /*
            uav.polyline.positions = new Cesium.PositionPropertyArray(all)
            */
        };
        // 改变飞机以及路线，home点实体样式
        const changeEntityStyle = (id, entityIcon, color, homeIcon) => {
            let flyPoint = getEntityById(id);
            if (flyPoint) {
                flyPoint.billboard.image = entityIcon || PlaneIcon;
            }
            let flyLine = getEntityById(`${id}-line`);
            let flyHomePoint = getEntityById(`${id}-HomePoint`);
            if (flyLine) {
                flyLine.polyline.material = Cesium.Color.fromCssColorString(color || "#FFB100");
                flyLine.polyline.depthFailMaterial = Cesium.Color.fromCssColorString(color || "#FFB100");
            }
            if (flyHomePoint) {
                flyHomePoint.billboard.image = homeIcon || HomeImg;
            }
        };
        // 通过id获取对应实体
        const getEntityById = (id) => {
            if (!id) return;
            return flylineLayer.getById(id);
        };
        // 定位到飞机实体
        const locationToEntity = (id) => {
            if (!id) return;
            let entity = flylineLayer.getById(id);
            if (!entity) return;
            // map3dCollection.zoomTo(entity);
            // map3dCollection.flyTo(entity, {
            //     offset: new Cesium.HeadingPitchRange(0, -90, 4000), // 设置heading,pitch和range
            //     duration: 1.5
            // });
            map3dCollection.zoomTo(
                entity,
                new Cesium.HeadingPitchRange(0, -90, 4000)
            );
        };
        // 切换是否跟随
        const changeFlightFollowing = (id, follwing) => {
            if (follwing !== undefined && follwing !== null) {
                isFlightFollowing = follwing
            } else {
                isFlightFollowing = !isFlightFollowing;
            }
            map3dCollection.trackedEntity = undefined;
            let entity;
            if (isFlightFollowing && id) {
                entity = flylineLayer.getById(id);
            }
            flightFollowing(entity);
            return isFlightFollowing;
        };
        // 通过id获取实体设置跟随
        const setFlightFollowingById = (id, follwing) => {
            if (!id) return;
            let entity = flylineLayer.getById(id);
            if (!entity) return;
            if (follwing) isFlightFollowing = follwing;
            map3dCollection.trackedEntity = undefined;
            flightFollowing(entity);
            return isFlightFollowing;
        };
        // 跟随设置
        const flightFollowing = (entity) => {
            if (isFlightFollowing) {
                if (!entity) return;
                map3dCollection.trackedEntity = entity;
            } else {
                map3dCollection.trackedEntity = undefined;
            }
        };
        // 添加飞机信息弹窗
        const addUavRealInfoPop = (options) => {
            if (!options) return;
            removeAllUavpops();
            const positions = options.positions;
            const height = options.height || 2;
            const id = options.id;
            const parentId = options.parentId;
            const X = options.x || 0;
            const Y = options.y || 0;
            popIds.push(options.id);
            popslist.push({
                id: id,
                positions: positions,
                x: X,
                y: Y
            });
            if (map3dCollection.scene.preRender._listeners.length) {
                map3dCollection.scene.preRender._listeners.forEach(item => {
                    if (item && item.name == 'pop') {
                        map3dCollection.scene.preRender.removeEventListener(item);
                    }
                });
            }
            let pop = () => {
                popslist.forEach(item => {
                    let mapContainer = document.getElementById(parentId);
                    let container = document.getElementById(item.id);
                    const x = item.x;
                    const y = item.y;
                    if (container) {
                        let windowPosition = new Cesium.Cartesian2();
                        let canvasWidth, canvasHeight;
                        if (mapContainer) {
                            canvasWidth = mapContainer.clientWidth;
                            canvasHeight = mapContainer.clientHeight;
                        } else {
                            canvasWidth = map3dCollection.scene.canvas.width;
                            canvasHeight = map3dCollection.scene.canvas.height;
                        }
                        Cesium.SceneTransforms.wgs84ToWindowCoordinates(
                            map3dCollection.scene,
                            Cesium.Cartesian3.fromDegrees(
                                item.positions[0],
                                item.positions[1],
                                item.positions[2] + height
                            ),
                            windowPosition
                        );
                        container.style.right = canvasWidth - windowPosition.x - x - container.offsetWidth * 0.5 + "px";
                        container.style.bottom = canvasHeight - windowPosition.y + y + "px";
                    }
                });
            };
            map3dCollection.scene.preRender.addEventListener(pop);
        };
        // 移除所有飞机信息弹窗
        const removeAllUavpops = () => {
            if (popslist.length) {
                popslist = [];
            }
            if (popIds.length) {
                if (map3dCollection.scene.preRender._listeners.length) {
                    map3dCollection.scene.preRender._listeners.forEach(item => {
                        if (item && item.name == 'pop') {
                            map3dCollection.scene.preRender.removeEventListener(item);
                        }
                    });
                }
                popIds = [];
            }
        };
        // 根据id集合清除多个指定的飞机实体，路线，可视区，home点等
        const clearUavEntity = (ids = []) => {
            ids.map(t => {
                flylineLayer.removeNodeById(t);
                flylineLayer.removeNodeById(`${t}-line`);
                flylineLayer.removeNodeById(`${t}-polygon`);
                flylineLayer.removeNodeById(`${t}-HomePoint`);
            });
        };
        // 单独清除当前flylineLayer层的飞行实体
        const clearFlylineLayer = () => {
            flylineLayer && flylineLayer.clear();
        };
        // 单独关闭清除当前hook维护的ws链接
        const closeWsLink = (ws) => {
            if (ws) {
                let wsItem = wsList.find(t => t == ws);
                if (wsItem) {
                    wsItem.close();
                    wsItem = null;
                }
            } else {
                wsList && wsList.map(t => {
                    t && t.close();
                });
                wsList = [];
            }
        };
        // 清除地图实例，图层实体，关闭ws链接
        const clearFlyRoute = () => {
            flylineLayer && flylineLayer.clear();
            map3dCollection.layerManager.remove(flylineLayer, true);
            flylineLayer = null;
            removeAllUavpops();
            map3dCollection = null;
            isShowVisualPolygon = null;
            isShowHomePoint = null;
            isFlightFollowing = null;
            wsList && wsList.map(t => {
                t && t.close();
            });
            wsList = [];
        };
        return {
            setMap,
            initWebSocket,
            handlingRoutes,
            findEntityById,
            initFlyRoute,
            changeFlyRoute,
            changeEntityStyle,
            getEntityById,
            locationToEntity,
            changeFlightFollowing,
            setFlightFollowingById,
            flightFollowing,
            addUavRealInfoPop,
            removeAllUavpops,
            clearUavEntity,
            clearFlylineLayer,
            closeWsLink,
            clearFlyRoute
        };
    })();
}

export default userFlyRouteHook;