const maxLon = 120.05;
const minLon = 100.66;
const maxLat = 25.55;
const minLat = 32.86;
const latOffset = maxLat - minLat;
const lonOffset = maxLon - minLon;

/**
 * random a 2d coordinates in china extend
 * @returns
 */
export function randomCoordinates() {
  let lat = minLat + latOffset * Math.random();
  let lon = minLon + lonOffset * Math.random();
  return [lon, lat];
}

export function cartesian2screen(map3d, cartesian) {
  return map3d.scene.cartesianToCanvasCoordinates(
    cartesian,
    new Cesium.Cartesian2()
  );
}

export function screen2cartesian3(map3d, position) {
  let ray = map3d.camera.getPickRay(position);
  let coord = map3d.scene.globe.pick(ray, map3d.scene);
  return coord;
}

export function getSpaceDistance(start, end) {
  let distance = 0;
  let point1cartographic = Cesium.Cartographic.fromCartesian(start);
  let point2cartographic = Cesium.Cartographic.fromCartesian(end);
  //
  let geodesic = new Cesium.EllipsoidGeodesic();
  geodesic.setEndPoints(point1cartographic, point2cartographic);
  let s = geodesic.surfaceDistance;
  //return distance
  s = Math.sqrt(
    Math.pow(s, 2) +
      Math.pow(point2cartographic.height - point1cartographic.height, 2)
  );
  distance = distance + s;
  return distance.toFixed(2);
}

export function getDistance(startPosition, endPosition) {
  let dis = Cesium.Cartesian3.distance(startPosition, endPosition);
  return dis;
}

/***
 * cartesia to wgs84
 * **/
export function cartesian2wgs(map3d, cartesian3) {
  let ellipsoid = map3d.scene.globe.ellipsoid;
  let cartographic = ellipsoid.cartesianToCartographic(cartesian3);
  let lat = Cesium.Math.toDegrees(cartographic.latitude);
  let lng = Cesium.Math.toDegrees(cartographic.longitude);
  let alt = cartographic.height;
  return [lng, lat, alt];
}

export function cartesianArr2wgsArr(map3d, cartesianArr) {
  let result = [];
  for (let i = 0; i < cartesianArr.length; i++) {
    result.push(cartesian2wgs(map3d, cartesianArr[i]));
  }
  return result;
}

export function wgsArr2cartesianArr(coords, hasHeight = true) {
  let result = [];
  for (let i = 0; i < coords.length; i++) {
    let cartesian = Cesium.Cartesian3.fromDegrees(
      coords[i][0],
      coords[i][1],
      hasHeight && coords[i][2] ? coords[i][2] : 0
    );
    result.push(cartesian);
  }
  return result;
}

/**
 * get entity by json
 * @param {*} json
 * @returns
 */
export function generateEntityByJson(json) {
  const geoJson = json.location;
  let s = null;
  try {
    s = JSON.parse(geoJson);
  } catch (ex) {
    return null;
  }
  if (!s.coordinates) {
    return null;
  }
  let coordinates = null;
  switch (s.type) {
    case "circular":
      coordinates = Cesium.Cartesian3.fromDegrees(
        s.coordinates[0],
        s.coordinates[1],
        s.coordinates[2] ? s.coordinates[2] : 0
      );
      return getCircular(
        coordinates,
        s.radius,
        json.id,
        json.regionName,
        json.type,
        s.isEnable
      );
    case "polygon":
      coordinates = wgsArr2cartesianArr(s.coordinates[0]);
      return getFencePolygon(
        coordinates,
        json.id,
        json.regionName,
        json.type,
        s.isEnable
      );
  }
}

/**
 * generate a circle by params
 * @param {*} center
 * @param {*} radius
 * @param {*} id
 * @param {*} name
 * @param {*} type
 * @returns
 */
export function getCircular(center, radius, id, name, type, isEnable) {
  let mainColor =
    type === "1"
      ? Cesium.Color.fromCssColorString("#00e3bb")
      : Cesium.Color.fromCssColorString("#e83527");
  const tempPolygon = new Cesium.Entity({
    id: id ? id : Cesium.createGuid(),
    position: center,
    show: isEnable,
    label: {
      text: name,
      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      verticalOrigin: Cesium.VerticalOrigin.MIDDLE,
      scaleByDistance: new Cesium.NearFarScalar(100, 1, 10000000, 0),
      showBackground: true,
      backgroundColor: mainColor,
      fillColor: Cesium.Color.White,
    },
    ellipse: {
      semiMinorAxis: radius,
      semiMajorAxis: radius,
      material: mainColor.withAlpha(0.3),
      outline: true,
      outlineColor: mainColor,
      outlineWidth: 1,
      height: 0,
    },
  });
  return tempPolygon;
}

/**
 * generate a polygon by params
 * @param {*} coordinates
 * @param {*} id
 * @param {*} name
 * @param {*} type
 * @returns
 */
export function getFencePolygon(coordinates, id, name, type, isEnable) {
  let mainColor =
    type === "1"
      ? Cesium.Color.fromCssColorString("#00e3bb")
      : Cesium.Color.fromCssColorString("#e83527");
  const tempPolygon = new Cesium.Entity({
    id: id ? id : Cesium.createGuid(),
    position: new Cesium.CallbackProperty(function () {
      let polyCenter = Cesium.BoundingSphere.fromPoints(coordinates).center;
      polyCenter = Cesium.Ellipsoid.WGS84.scaleToGeodeticSurface(polyCenter);
      return polyCenter;
    }, false),
    name: name,
    show: isEnable,
    label: {
      text: name,
      horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      verticalOrigin: Cesium.VerticalOrigin.MIDDLE,
      scaleByDistance: new Cesium.NearFarScalar(100, 1, 10000000, 0),
      showBackground: true,
      backgroundColor: mainColor,
      fillColor: Cesium.Color.White,
    },
    polygon: {
      hierarchy: new Cesium.PolygonHierarchy(coordinates),
      material: mainColor.withAlpha(0.3),
      outline: true,
      outlineColor: mainColor,
      outlineWidth: 1,
      height: 0,
    },
  });
  return tempPolygon;
}

export function drawCanvas(number, color) {
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");
  canvas.width = 48;
  canvas.height = 48;
  let radius = canvas.width / 2;
  ctx.beginPath();
  ctx.arc(radius, radius, radius, 0, 2 * Math.PI);
  ctx.fillStyle = color ? color : "rgba(17, 85, 204,0.52)";
  ctx.fill();
  ctx.lineWidth = 0;
  ctx.stroke();
  ctx.font = "bold 24px Arial";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillStyle = "#fff";
  ctx.fillText(number, radius, radius);
  return canvas.toDataURL("image/png");
}

export function modelToGroundMatrix(model) {
  // Position tileset
  let boundingSphere = model.boundingSphere;
  let cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center);
  let surface = Cesium.Cartesian3.fromRadians(
    cartographic.longitude,
    cartographic.latitude,
    cartographic.height
  );
  let offset = Cesium.Cartesian3.fromRadians(
    cartographic.longitude,
    cartographic.latitude,
    0
  );
  let translation = Cesium.Cartesian3.subtract(
    offset,
    surface,
    new Cesium.Cartesian3()
  );
  return translation;
}

/**
 * 飞控数据转换为坐标
 */
export function string2position(value, isHeight) {
  const valueN = value * 1;
  if (isHeight) {
    return valueN / 1000;
  }
  return valueN / Math.pow(10, 7);
}

export function segmentsIntr(a, b, c, d) {
  // 三角形abc 面积的2倍
  let area_abc = (a.x - c.x) * (b.y - c.y) - (a.y - c.y) * (b.x - c.x);

  // 三角形abd 面积的2倍
  let area_abd = (a.x - d.x) * (b.y - d.y) - (a.y - d.y) * (b.x - d.x);

  // 面积符号相同则两点在线段同侧,不相交 (对点在线段上的情况,本例当作不相交处理);
  if (area_abc * area_abd >= 0) {
    return false;
  }

  // 三角形cda 面积的2倍
  let area_cda = (c.x - a.x) * (d.y - a.y) - (c.y - a.y) * (d.x - a.x);
  // 三角形cdb 面积的2倍
  // 注意: 这里有一个小优化.不需要再用公式计算面积,而是通过已知的三个面积加减得出.
  let area_cdb = area_cda + area_abc - area_abd;
  if (area_cda * area_cdb >= 0) {
    return false;
  }

  //计算交点坐标
  let t = area_cda / (area_abd - area_abc);
  let dx = t * (b.x - a.x),
    dy = t * (b.y - a.y);
  return { x: a.x + dx, y: a.y + dy };
}

export function lengthOfLine(a, b) {
  const lineLength = Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
  return lineLength;
}

//度转度°分′秒″
export function degressFormat(val) {
  if (typeof val == "undefined" || val == "") {
    return "";
  }
  val = val.toString();
  let i = val.indexOf(".");
  let strDu = i < 0 ? val : val.substring(0, i); //获取度
  let strFen = 0;
  let strMiao = 0;
  if (i > 0) {
    let strFen = "0" + val.substring(i);
    strFen = strFen * 60 + "";
    i = strFen.indexOf(".");
    if (i > 0) {
      strMiao = "0" + strFen.substring(i);
      strFen = strFen.substring(0, i); //获取分
      strMiao = strMiao * 60 + "";
      i = strMiao.indexOf(".");
      strMiao = strMiao.substring(0, i + 4); //取到小数点后面三位
      strMiao = parseFloat(strMiao).toFixed(2); //精确小数点后面两位
    }
  }
  return `${strDu}°${strFen}′${strMiao}″`;
}

export function getSegmentInfo(map, start, end, widthHeight = true) {
  const startCartographic = Cesium.Cartographic.fromCartesian(start);
  const endCartographic = Cesium.Cartographic.fromCartesian(end);
  const geodesic = new Cesium.EllipsoidGeodesic(
    startCartographic,
    endCartographic
  );

  // const height1 = map.scene.sampleHeight(startCartographic);
  // const height2 = map.scene.sampleHeight(endCartographic);

  // get length
  let length = geodesic.surfaceDistance;
  length = Math.sqrt(
    Math.pow(length, 2) +
      Math.pow(endCartographic.height - startCartographic.height, 2)
  );

  // get mind point
  const midpointCartographic = geodesic.interpolateUsingFraction(0.5);
  const mindPoint = Cesium.Cartesian3.fromRadians(
    midpointCartographic.longitude,
    midpointCartographic.latitude,
    midpointCartographic.height
  );
  return {
    length,
    mindPoint,
    height: endCartographic.height - startCartographic.height,
  };
}

export function geometry2cartesain(geometry) {
  let max = [0, 0];
  let min = [1000, 1000];
  if (geometry.type === "Polygon") {
    let coord = [];
    geometry.coordinates.map((hole) => {
      hole.map((point) => {
        const lng = point[0];
        const lat = point[1];
        if (max[0] < lng) {
          max[0] = lng;
        }
        if (max[1] < lat) {
          max[1] = lat;
        }
        if (min[0] > lng) {
          min[0] = lng;
        }
        if (min[1] > lat) {
          min[1] = lat;
        }
        coord.push(Cesium.Cartesian3.fromDegrees(lng, lat));
      });
    });
    return {
      bound: [...min, ...max],
      coord,
    };
  }
  return null;
}

export function setCartesian3Height(viewer, cartesian3, height, replace) {
  const ellipsoid = viewer.scene.globe.ellipsoid;
  const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
  const lat = Cesium.Math.toDegrees(cartographic.latitude);
  const lng = Cesium.Math.toDegrees(cartographic.longitude);

  const cartesian = Cesium.Cartesian3.fromDegrees(
    lng,
    lat,
    replace ? height : cartographic.height + height
  );
  return cartesian;
}

/**
 * 获取84坐标的距离 球面 带高度距离
 * @param {*} positions
 */
export function getPositionDistance(positions) {
  let distance = 0;
  for (let i = 0; i < positions.length - 1; i++) {
    const s = getLength(positions[i], positions[i + 1]);
    distance = distance + s;
  }
  return distance;
}

// 计算两点距离
export function getLength(start, end) {
  const startCartographic = Cesium.Cartographic.fromCartesian(start);
  const endCartographic = Cesium.Cartographic.fromCartesian(end);
  const geodesic = new Cesium.EllipsoidGeodesic(
    startCartographic,
    endCartographic
  );
  let s = geodesic.surfaceDistance;
  s = Math.sqrt(
    Math.pow(s, 2) +
      Math.pow(endCartographic.height - startCartographic.height, 2)
  );
  return s;
}

export function getCanvasLabel(label, fontSize = 14, left = 0, top = 10) {
  const c = document.createElement("canvas");
  c.width = label.length * fontSize * 0.8 + left;
  c.height = fontSize + top * 2;
  const ctx = c.getContext("2d");
  ctx.font = `bold ${fontSize}px Arial`;
  ctx.fillStyle = "rgba(0,0,0, 0.8)";
  ctx.fillRect(0, 0, c.width, c.height);
  ctx.fillStyle = "#ffffff";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillText(label, c.width / 2, c.height / 2);
  return c.toDataURL("image/png");
}

/*方向*/
export function bearing(from, to) {
  const radiansPerDegree = Math.PI / 180.0; //角度转化为弧度(rad)
  const degreesPerRadian = 180.0 / Math.PI;
  from = Cesium.Cartographic.fromCartesian(from);
  to = Cesium.Cartographic.fromCartesian(to);

  let lat1 = from.latitude * radiansPerDegree;
  let lon1 = from.longitude * radiansPerDegree;
  let lat2 = to.latitude * radiansPerDegree;
  let lon2 = to.longitude * radiansPerDegree;
  let angle = -Math.atan2(
    Math.sin(lon1 - lon2) * Math.cos(lat2),
    Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)
  );
  if (angle < 0) {
    angle += Math.PI * 2.0;
  }
  angle = angle * degreesPerRadian; //角度
  return angle;
}

/**
 * 计算一组坐标组成多边形的面积
 * @param {*} positions
 */
export function getPositionsArea(positions) {
  let result = 0;
  if (positions) {
    let h = 0;
    let temp = [...positions];
    temp.push(temp[0]);
    for (let i = 1; i < temp.length; i++) {
      let oel = temp[i - 1];
      let el = temp[i];
      h += oel.x * el.y - el.x * oel.y;
    }
    result = Math.abs(h);
  }
  if (result < 1000000) {
    result = Math.abs(result).toFixed(4) + "m²";
  } else {
    result = Math.abs((result / 1000000.0).toFixed(4)) + "km²";
  }
  return result;
}

//计算多边形面积
export function getSpaceArea(points) {
  /*角度*/
  function Angle(p1, p2, p3) {
    let bearing21 = bearing(p2, p1);
    let bearing23 = bearing(p2, p3);
    let angle = bearing21 - bearing23;
    if (angle < 0) {
      angle += 360;
    }
    return angle;
  }

  function distance(point1, point2) {
    let point1cartographic = Cesium.Cartographic.fromCartesian(point1);
    let point2cartographic = Cesium.Cartographic.fromCartesian(point2);
    /**根据经纬度高度计算出距离**/
    let geodesic = new Cesium.EllipsoidGeodesic();
    geodesic.setEndPoints(point1cartographic, point2cartographic);
    let s = geodesic.surfaceDistance;
    //console.log(Math.sqrt(Math.pow(distance, 2) + Math.pow(endheight, 2)));
    //返回两点之间的距离
    s = Math.sqrt(
      Math.pow(s, 2) +
        Math.pow(point2cartographic.height - point1cartographic.height, 2)
    );
    return s;
  }

  let res = 0;
  //拆分三角曲面

  for (let i = 0; i < points.length - 2; i++) {
    let j = (i + 1) % points.length;
    let k = (i + 2) % points.length;
    let totalAngle = Angle(points[i], points[j], points[k]);

    let dis_temp1 = distance(points[j], points[i]);
    let dis_temp2 = distance(points[j], points[k]);
    res += (dis_temp1 * dis_temp2 * Math.sin(totalAngle)) / 2;
    // console.log(res);
  }

  if (res < 1000000) {
    res = Math.abs(res).toFixed(4) + "m²";
  } else {
    res = Math.abs((res / 1000000.0).toFixed(4)) + "km²";
  }

  return res;
}

export function volumeAnalysisWithModel(points, map, extraHeight) {
  let minHeight = 1000000;
  let maxHeight = 0;
  if (extraHeight !== null && extraHeight !== undefined) {
    minHeight = extraHeight;
  } else {
    for (let i = 0; i < points.length; i++) {
      let cartographic = Cesium.Cartographic.fromCartesian(points[i]);
      let height = map.scene.sampleHeight(cartographic);
      if (minHeight > height) minHeight = height;
    }
  }
  //设置网格粒度
  let granularity = Math.PI / Math.pow(2, 11);
  granularity = granularity / 64;
  //创建多边形平面几何体
  let polygonGeometry = new Cesium.PolygonGeometry.fromPositions({
    positions: points,
    vertexFormat: Cesium.PerInstanceColorAppearance.FLAT_VERTEX_FORMAT,
    granularity: granularity,
  });
  //创建多边形平面几何体
  let geom = new Cesium.PolygonGeometry.createGeometry(polygonGeometry);
  let i0, i1, i2;
  let height1, height2, height3;
  let p1, p2, p3;
  let cartesian;
  let cartographic;
  let bottomArea;
  let digVolume = 0;
  let fillVolume = 0;
  //循环计算网格的节点, indices顶点索引数据，用于确定几何中的基元
  for (let i = 0; i < geom.indices.length; i += 3) {
    i0 = geom.indices[i];
    i1 = geom.indices[i + 1];
    i2 = geom.indices[i + 2];
    //获取几何体三角面第一个面点
    cartesian = new Cesium.Cartesian3(
      geom.attributes.position.values[i0 * 3],
      geom.attributes.position.values[i0 * 3 + 1],
      geom.attributes.position.values[i0 * 3 + 2]
    );
    cartographic = Cesium.Cartographic.fromCartesian(cartesian);
    height1 = map.scene.sampleHeight(cartographic);
    p1 = Cesium.Cartesian3.fromRadians(
      cartographic.longitude,
      cartographic.latitude,
      0
    );
    if (maxHeight < height1) maxHeight = height1;
    //获取几何体三角面第二个面点
    cartesian = new Cesium.Cartesian3(
      geom.attributes.position.values[i1 * 3],
      geom.attributes.position.values[i1 * 3 + 1],
      geom.attributes.position.values[i1 * 3 + 2]
    );
    cartographic = Cesium.Cartographic.fromCartesian(cartesian);
    //从网格的节点计算地面高度
    height2 = map.scene.sampleHeight(cartographic);
    p2 = Cesium.Cartesian3.fromRadians(
      cartographic.longitude,
      cartographic.latitude,
      0
    );
    if (maxHeight < height2) maxHeight = height2;
    //获取几何体三角面第三个面点
    cartesian = new Cesium.Cartesian3(
      geom.attributes.position.values[i2 * 3],
      geom.attributes.position.values[i2 * 3 + 1],
      geom.attributes.position.values[i2 * 3 + 2]
    );
    cartographic = Cesium.Cartographic.fromCartesian(cartesian);
    height3 = map.scene.sampleHeight(cartographic);
    p3 = Cesium.Cartesian3.fromRadians(
      cartographic.longitude,
      cartographic.latitude,
      0
    );
    if (maxHeight < height3) maxHeight = height3;
    //计算三角面的面积
    bottomArea = getPositionsArea([p1, p2, p3]);
    //计算体积此三角元体积
    const trangleVloume =
      bottomArea * ((height1 + height2 + height3) / 3 - minHeight);
    if (trangleVloume > 0) {
      digVolume = digVolume + trangleVloume;
    } else {
      fillVolume = fillVolume + trangleVloume;
    }
  }
  return { fillVolume, digVolume };
}

export function volumeAnalysis(
  map,
  points,
  callback,
  isTileSet = false,
  isCustome = false,
  extraHeight
) {
  if (isTileSet) {
    const vloume = volumeAnalysisWithModel(points, map, extraHeight);
    if (callback) {
      callback(vloume);
    }
    return;
  }
  const provider = Cesium.createWorldTerrain();
  const allPosition = [];
  const allArea = [];
  let granularity = Math.PI / Math.pow(2, 11);
  granularity = granularity / 64;
  //创建多边形平面几何体
  let polygonGeometry = new Cesium.PolygonGeometry.fromPositions({
    positions: points,
    vertexFormat: Cesium.PerInstanceColorAppearance.FLAT_VERTEX_FORMAT,
    granularity: granularity,
  });
  //创建多边形平面几何体
  let geom = new Cesium.PolygonGeometry.createGeometry(polygonGeometry);
  let i0, i1, i2;
  let p1, p2, p3;
  let cartesian;
  let cartographic;
  let bottomArea;
  let digVolume = 0;
  let fillVolume = 0;
  //循环计算网格的节点, indices顶点索引数据，用于确定几何中的基元
  for (let i = 0; i < geom.indices.length; i += 3) {
    i0 = geom.indices[i];
    i1 = geom.indices[i + 1];
    i2 = geom.indices[i + 2];
    //获取几何体三角面第一个面点
    cartesian = new Cesium.Cartesian3(
      geom.attributes.position.values[i0 * 3],
      geom.attributes.position.values[i0 * 3 + 1],
      geom.attributes.position.values[i0 * 3 + 2]
    );
    cartographic = Cesium.Cartographic.fromCartesian(cartesian);

    allPosition.push(cartographic);

    p1 = Cesium.Cartesian3.fromRadians(
      cartographic.longitude,
      cartographic.latitude,
      0
    );

    //获取几何体三角面第二个面点
    cartesian = new Cesium.Cartesian3(
      geom.attributes.position.values[i1 * 3],
      geom.attributes.position.values[i1 * 3 + 1],
      geom.attributes.position.values[i1 * 3 + 2]
    );
    let cartographic2 = Cesium.Cartographic.fromCartesian(cartesian);

    allPosition.push(cartographic2);

    p2 = Cesium.Cartesian3.fromRadians(
      cartographic2.longitude,
      cartographic2.latitude,
      0
    );

    //获取几何体三角面第三个面点
    cartesian = new Cesium.Cartesian3(
      geom.attributes.position.values[i2 * 3],
      geom.attributes.position.values[i2 * 3 + 1],
      geom.attributes.position.values[i2 * 3 + 2]
    );

    let cartographic3 = Cesium.Cartographic.fromCartesian(cartesian);

    allPosition.push(cartographic3);

    p3 = Cesium.Cartesian3.fromRadians(
      cartographic3.longitude,
      cartographic3.latitude,
      0
    );

    //计算三角面的面积
    bottomArea = getPositionsArea([p1, p2, p3]);
    allArea.push(bottomArea);
  }

  let promise = Cesium.sampleTerrain(provider, 11, allPosition);
  Cesium.when(
    promise,
    function (updatedPositions) {
      let max = 0;
      let min = 100000000;
      if (isCustome) {
        min = extraHeight;
      } else {
        updatedPositions.forEach((ele) => {
          if (ele.height > max) {
            max = ele.height;
          }
          if (ele.height < min) {
            min = ele.height;
          }
        });
      }

      for (let i = 0; i < updatedPositions.length; i += 3) {
        const heigth1 = updatedPositions[i].height;
        const height2 = updatedPositions[i + 1].height;
        const height3 = updatedPositions[i + 2].height;
        const vloume =
          ((heigth1 - min + height2 - min + height3 - min) / 3) *
          allArea[i / 3];
        if (vloume < 0) {
          fillVolume = fillVolume + vloume;
        } else {
          digVolume = digVolume + vloume;
        }
      }
      if (callback) {
        callback({ fillVolume, digVolume });
      }
    },
    function (err) {
      console.log(err);
    }
  );
}

const x_pi = (3.14159265358979324 * 3000.0) / 180.0;
const pi = Math.PI; //π
const a = 6378245.0; // 长半轴
const ee = 0.00669342162296594323; // 扁率

export function wgs84togcj02(lng, lat) {
  let dlat = transformlat(lng - 105.0, lat - 35.0);
  let dlng = transformlng(lng - 105.0, lat - 35.0);
  let radlat = (lat / 180.0) * pi;
  let magic = Math.sin(radlat);
  magic = 1 - ee * magic * magic;
  let sqrtmagic = Math.sqrt(magic);
  dlat =
    (dlat * 180.0) /
    (((a * (1 - ee)) / (magic * sqrtmagic)) * pi);
  dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * pi);
  let mglat = lat + dlat;
  let mglng = lng + dlng;
  return [mglng, mglat];
}

export function gcj02towgs84(lng, lat) {
  // GCJ02(火星坐标系) 转GPS84: param lng: 火星坐标系的经度: param lat: 火星坐标系纬度: return :
  let dlat = transformlat(lng - 105.0, lat - 35.0);
  let dlng = transformlng(lng - 105.0, lat - 35.0);
  let radlat = (lat / 180.0) * pi;
  let magic = Math.sin(radlat);
  magic = 1 - ee * magic * magic;
  let sqrtmagic = Math.sqrt(magic);
  dlat =
    (dlat * 180.0) /
    (((a * (1 - ee)) / (magic * sqrtmagic)) * pi);
  dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * pi);
  let mglat = lat + dlat;
  let mglng = lng + dlng;
  return [lng * 2 - mglng, lat * 2 - mglat];
}

export function transformlat(lng, lat) {
  let ret =
    -100.0 +
    2.0 * lng +
    3.0 * lat +
    0.2 * lat * lat +
    0.1 * lng * lat +
    0.2 * Math.sqrt(Math.abs(lng));
  ret +=
    ((20.0 * Math.sin(6.0 * lng * pi) +
      20.0 * Math.sin(2.0 * lng * pi)) *
      2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(lat * pi) + 40.0 * Math.sin((lat / 3.0) * pi)) *
      2.0) /
    3.0;
  ret +=
    ((160.0 * Math.sin((lat / 12.0) * pi) +
      320 * Math.sin((lat * pi) / 30.0)) *
      2.0) /
    3.0;
  return ret;
}

export function transformlng(lng, lat) {
  let ret =
    300.0 +
    lng +
    2.0 * lat +
    0.1 * lng * lng +
    0.1 * lng * lat +
    0.1 * Math.sqrt(Math.abs(lng));
  ret +=
    ((20.0 * Math.sin(6.0 * lng * pi) +
      20.0 * Math.sin(2.0 * lng * pi)) *
      2.0) /
    3.0;
  ret +=
    ((20.0 * Math.sin(lng * pi) + 40.0 * Math.sin((lng / 3.0) * pi)) *
      2.0) /
    3.0;
  ret +=
    ((150.0 * Math.sin((lng / 12.0) * pi) +
      300.0 * Math.sin((lng / 30.0) * pi)) *
      2.0) /
    3.0;
  return ret;
}
